<template>
	<div>
		<div class="nodata" v-if="lists.count == 0"><img src="../assets/img/nofeedback.png" alt="" /></div>
		<div class="lists" v-else>
			<div class="item" v-for="item in lists.list" :key="item.id" @click="onView(item)">
				<span class="content">{{ item.content | strLength }}</span>
				<span class="date">{{ item.created_at }}</span>
			</div>
			<a-pagination class="pagination" :current="lists.page" :total="lists.count" :page-size="10" @change="onPageChange" />
		</div>
		<a-modal v-model="modal.visible" :title="null" :footer="null" :width="800" wrap-class-name="modal-feedback">
			<h4>反馈内容</h4>
			<div class="content">{{ modal.data.content }}</div>
			<template v-if="modal.data.imgs && modal.data.imgs.length > 0">
				<h4>反馈图片</h4>
				<div class="content"><a-avatar v-for="(img, idx) in modal.data.imgs" :key="idx" shape="square" :size="120" :src="img"></a-avatar></div>
			</template>
			<h4>联系方式</h4>
			<div class="content">{{ modal.data.contact || '无' }}</div>
		</a-modal>
	</div>
</template>

<script>
export default {
	name: 'UserFeedback',
	data() {
		return {
			feedbacks: [],
			lists: {
				page: 1,
				count: 0,
				list: [],
				next: 0
			},
			modal: {
				visible: false,
				data: {}
			}
		};
	},
    filters:{
        strLength(val){
            if(val.length > 25){
                return  val.slice(0,25)+'......'
            }else {
                return val
            }
        }
    },
	mounted() {
		// this.$router.replace('/user/feedback/create')
		this.getFeedbacks();
	},
	methods: {
		async getFeedbacks() {
			const response = await this.$api.get(`/web/feedback_list?page=${this.lists.page}`);
			if (response.code == 200) {
				// if (response.data.count == 0) {
				// 	this.$router.replace('/user/feedback/create');
				// }
				Object.assign(this.lists, response.data);
			}
		},
		async onPageChange(page) {
			this.lists.page = page;
			this.getFeedbacks();
		},
		async onView(item) {
			this.modal.data = Object.assign({}, item, {
				imgs: item.imgs ? item.imgs.split(',') : []
			});
			this.modal.visible = true;
		}
	}
};
</script>

<style lang="less">
.lists {
	.item {
		display: flex;
		align-items: center;
		height: 64px;
		border-bottom: #f7f7f8 1px solid;
		color: #1f232c;
		cursor: pointer;
		.content {
			flex: 1;
		}
		.date {
			color: #999999;
			font-size: 14px;
		}
	}
}

.pagination {
	margin-top: 16px;
}

.modal-feedback {
	.ant-modal-body {
		padding: 60px;

		h4 {
			margin: 0;
			padding: 10px 0;
			font-size: 18px;
			color: #26293a;
		}
		.content {
			font-size: 16px;
			color: #565969;
			line-height: 1.8;
			+ h4 {
				margin-top: 60px;
			}

			.ant-avatar {
				margin-right: 20px;
			}
		}
	}
}
</style>
